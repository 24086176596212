<template>
  <div style="height: 900px">
    <!-- <div class="info" style="height: 15%" >
      <span>Center: {{ center }}</span>
      <span>Zoom: {{ zoom }}</span>
      <span>Bounds: {{ bounds }}</span>
    </div> -->
    <l-map
      style="height: 80%; width: 100%"
      :zoom="zoom"
      :center="center"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
      @update:bounds="boundsUpdated"
    >
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker
        v-for="item in pois"
        v-bind:key="item.id"
        :lat-lng="item.coordinates"
      >
        <l-popup>
          <div>
            <p>
              {{ item.name }}
            </p>
            <p v-show="item.openinghours">
              Öffnungszeiten
              {{ item.openinghours }}
            </p>
            <a :v-if="item.website" :href="item.website" target="_blank">{{
              item.website
            }}</a>
          </div>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LMarker, LPopup, LTileLayer } from "vue2-leaflet";
import pubs from "../assets/pubs.json";
import { latLng, Icon, icon } from "leaflet";
import iconUrl from "../assets/Beer-mug-silhouette.svg";

export default {
  name: "HelloMap2",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    let customicon = icon(
      Object.assign({}, Icon.Default.prototype.options, { iconUrl })
    );
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 11,
      center: [50.97923191430942, 11.026933864301782],
      bounds: null,
      pois: [],
      showParagraph: true,
      icon: customicon,
    };
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    getCoordinates(geometry) {
      if (geometry == undefined) return null;
      if (geometry.type === "Point") {
        return latLng(geometry.coordinates[1], geometry.coordinates[0]);
      }
      if (geometry.type === "Polygon") {
        return latLng(
          geometry.coordinates[0][0][1],
          geometry.coordinates[0][0][0]
        );
      }
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      // window.open(
      //         "https://www.geeksforgeeks.org", "_blank");
      alert("Click!");
    },
  },
  created() {
    if (pubs == null || pubs.features == null) return;

    pubs.features.forEach((pub) => {
      let currentPub = {
        id: pub.id,
        name: pub.properties.name,
        openinghours: pub.properties.opening_hours,
        website: pub.properties["contact:website"],
        coordinates: this.getCoordinates(pub.geometry),
      };
      if (currentPub.name === undefined || currentPub.name == null) {
        console.log(pub);
        currentPub.name = pub.properties.loc_name;
      }
      if (
        currentPub.name !== undefined &&
        currentPub.name !== null &&
        currentPub.name !== ""
      ) {
        this.pois.push(currentPub);
      }
    });
  },
};
</script>
