<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <HelloMap2 />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import HelloMap from "@/components/HelloMap.vue";
import HelloMap2 from "@/components/HelloMap2.vue";

export default {
  name: "Home",
  components: {
    HelloMap2,
  },
};
</script>
